export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "clusters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kubernetes Cluster"])},
        "resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resources Package"])},
        "kuboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuboard Integration"])}
      },
      "zh": {
        "clusters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["集群管理"])},
        "resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资源包管理"])},
        "kuboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuboard 集成"])}
      }
    }
  })
}
